import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

import AppLayout from "../../components/shared/AppLayout";
import errorTranslationKeyStore from "../../store/errorTranslationKeyStore";
import banner from "../../assets/images/samsung-care-plus-unboxing.jpg";
import failedIcon from "../../assets/images/failed.svg";

const isNotCoveredUnderSamsungCareKey = "partnerService.validateDevice.invalidDevice" || "samsungCarePlusService.validateDevice.httpStatusNotOk" || "samsungCarePlusService.getDeviceDetail.httpStatusNotOk";
const messageBasedOnKey = () => {
    if (errorTranslationKeyStore.translationKey === isNotCoveredUnderSamsungCareKey) {
        return "imei.unsucessful.description.samsungCareNotCovered";
    } else {
        return "imei.unsucessful.description.registedOver14Days";    
    }
};

const PageDeviceInfoUnsuccessful = () => {
    
    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} isBackBtn={true} hasFooter={true}>

            <div className="app-page page-device-info-unsuccessful">
                <div className="device-info-unsuccessful">

                    <div className="device-info-unsuccessful__banner">
                        <img className="img-fluid" alt="" src={banner} />
                    </div>

                    <div className="device-info-unsuccessful__details">
                        <div className="container">
                            <h2 className="device-info-unsuccessful__title text-bold">
                                <FormattedMessage id="imei.unsucessful.title" />
                            </h2>
                            <ul className="device-info-unsuccessful__list">
                                <li className="device-info-unsuccessful__item">
                                    <img className="device-info-unsuccessful__icon img-fluid" src={failedIcon} alt="" />
                                    <FormattedMessage id={messageBasedOnKey()} />
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>

        </AppLayout>
    );
};

export default PageDeviceInfoUnsuccessful;
