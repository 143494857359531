import { decorate, observable, action } from "mobx";
import { create, persist } from "mobx-persist";

class ErrorTranslationKeyStore {
    translationKey = "";

    updateTranslationKey = translationKey => {
        this.translationKey = translationKey;
    }
}

decorate(ErrorTranslationKeyStore, {
    translationKey: [persist, observable],
    updateTranslationKey: action,
});

var errorTranslationKeyStore = new ErrorTranslationKeyStore();

export default errorTranslationKeyStore;

if (typeof window !== "undefined") {
    const hydrate = create({
        storage: localStorage,
        jsonify: true,
    });
    hydrate("errorTranslationKeyStore", errorTranslationKeyStore);
}
